import React, { FC } from 'react'
import { Box } from '@/components/atoms/Grid'
import styled from 'styled-components'
import Spinner, { SpinnerSize } from '@/components/molecules/spinner'
import { useAdditionalInfoProvider } from '@/providers/additionalInfoProvider'
import {
  Head,
  Body,
  BodyCell,
  BodyRow,
  HeadRow,
  HeadCell
} from '@/components/molecules/table'
import {
  isRunningExperimentActive,
  useExperiment
} from '@/providers/experimentProvider'
import useTranslation from 'next-translate/useTranslation'
import { ExperimentType } from '@/providers/experimentProvider'
import { Routes } from '@/core/routes'
import { AdditionalInfoPageType } from '@/network/graphql.g'
import { useHost } from '@/providers/storeProvider'
import useDevice from 'hooks/useDevice'
import { BorderedButton } from '../atoms/button'
import { notifyError, notifySuccess } from '@/core/toast'

type AdditionalInfoItemProps = {
  title: string
}

const AdditionalInfoItem: FC<AdditionalInfoItemProps> = ({
  title,
  children
}) => (
  <Box mb="15px">
    {title}: {children}
  </Box>
)

const AdditionalInfoContent: FC = () => {
  const { additionalData, loading, pageType, id, productIds } =
    useAdditionalInfoProvider()
  const { t } = useTranslation('common')
  const { getRunningExperiments, getVariant } = useExperiment()
  const host = useHost()
  const { isMobile } = useDevice()

  const copyClipboard = async () => {
    const inputInfoPage = document
      .getElementById('input-information-page')
      .getElementsByTagName('input')[0]
    inputInfoPage.select()
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(inputInfoPage.value)
    } else {
      notifyError(t('Common.generalError'))
    }
  }

  const copyProductIds = async (productIds: string[]) => {
    const textToCopy = productIds.join(', ')
    try {
      await navigator.clipboard.writeText(textToCopy)
      notifySuccess(t('AdditionalInfo.Action.copiedSuccessfully'))
    } catch (error) {
      notifyError(t('Common.generalError'))
    }
  }

  const categoryUrl =
    pageType === AdditionalInfoPageType.Category
      ? `${host}/index.php?route=product/category&path=${id}`
      : ''

  return loading ? (
    <StyledSpinner />
  ) : (
    <>
      {pageType === AdditionalInfoPageType.Category && (
        <Box mt="5px" p="10px">
          <AdditionalInfoItem title="ID">
            <BoldVariant>{id}</BoldVariant>
          </AdditionalInfoItem>
          <AdditionalInfoItem title="Url">
            <StyledA href={categoryUrl} target="_blank">
              <BoldVariant>{categoryUrl}</BoldVariant>
            </StyledA>
          </AdditionalInfoItem>
        </Box>
      )}
      <AdditionalInfoTable>
        <Head>
          <HeadRow>
            <HeadCell colSpan={2} textAlign="center">
              {t('AdditionalInfo.RunningExperiments.title')}
            </HeadCell>
          </HeadRow>
        </Head>
        <Body>
          {getRunningExperiments().map((experiment) => {
            const activeVariant = getVariant(experiment.name as ExperimentType)
            const active = isRunningExperimentActive(experiment)
            return (
              <BodyRow key={experiment.id}>
                <BodyCell py="2px" pl="10px">
                  <StyledSpan active={active}>{experiment.name}</StyledSpan>
                </BodyCell>
                <StyledBodyCell py="2px">
                  {experiment.variants.map((variant) => (
                    <StyledVariants key={variant.variantIndex}>
                      {activeVariant === variant.variantIndex.toString() ? (
                        <BoldVariant>{variant.name}</BoldVariant>
                      ) : (
                        <StyledA
                          href={`${Routes.home}?changeVariantById=${experiment.id}.${variant.variantIndex}`}
                        >
                          {variant.name}
                        </StyledA>
                      )}
                    </StyledVariants>
                  ))}
                </StyledBodyCell>
              </BodyRow>
            )
          })}

          {!isMobile && (
            <BodyRow>
              <BodyCell py="2px" pl="10px">
                <Box
                  id="input-information-page"
                  dangerouslySetInnerHTML={{ __html: additionalData }}
                />
              </BodyCell>
              <StyledBodyCellButton py="2px">
                <BorderedButton
                  title={t('AdditionalInfo.Action.copyJsonClipboard')}
                  onClick={copyClipboard}
                />
              </StyledBodyCellButton>
            </BodyRow>
          )}
          <BodyRow>
            <BodyCell py="2px" pl="10px">
              <Box
                id="input-productIds"
                dangerouslySetInnerHTML={{
                  __html:
                    productIds.slice(0, 3).join(', ') +
                    (productIds.length > 3 ? '...' : '')
                }}
              />
            </BodyCell>
            <StyledBodyCellButton py="2px">
              <BorderedButton
                title={t('AdditionalInfo.Action.copyProductIdsClipboard')}
                onClick={() => copyProductIds(productIds)}
              >
                {t('AdditionalInfo.Action.copy')}
              </BorderedButton>
            </StyledBodyCellButton>
          </BodyRow>
        </Body>
      </AdditionalInfoTable>
    </>
  )
}

export default AdditionalInfoContent

const position = (size) => `calc(50% - ${size / 2}px)`

const StyledSpinner = styled(Spinner)`
  position: fixed;
  top: ${position(SpinnerSize)};
  left: ${position(SpinnerSize)};
  z-index: 1000;
`
const AdditionalInfoTable = styled.table`
  & td:first-child {
    padding-left: 0px;
  }
  & td:last-child {
    padding-right: 0px;
  }
  & tr:last-child {
    display: table-row;
  }
`
const StyledBodyCell = styled(BodyCell)`
  display: flex;
  flex-direction: row;
`
const StyledVariants = styled.div`
  padding: 0 5px;
`
const BoldVariant = styled.span`
  font-weight: bold;
`
const StyledA = styled.a`
  color: ${({ theme }) => theme.colors.accent};
  &:hover {
    color: ${({ theme }) => theme.colors.onBackground};
  }
`
const StyledSpan = styled.span`
  opacity: ${({ active }) => (active ? 1 : 0.2)};
`
const StyledBodyCellButton = styled(BodyCell)`
  vertical-align: bottom;
`
